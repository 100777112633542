







































































































































































































import { timeFormat } from "@/utils/util";
import EventBus from "@/utils/EventBus";
import {
    apiPhysicalVouchersCouponBackLists,
    apiPhysicalVouchersApprovalCouponBack
} from "@/api/card/physical_vouchers";
import { Component, Prop, Vue } from "vue-property-decorator";
import lsPagination from "@/components/ls-pagination.vue";
import LsDialog from "@/components/ls-dialog.vue";
import { RequestPaging, debounce } from "@/utils/util";
import ExportData from "@/components/export-data/index.vue";

@Component({
    components: {
        lsPagination,
        ExportData,
        LsDialog,
    },
})
export default class reserveCardList extends Vue {
    apiExport = apiPhysicalVouchersCouponBackLists;

    pager: RequestPaging = new RequestPaging();
    searchObj: any = {
        sn: "",
        start_application_time: "",
        end_application_time: "",
        number: "",
        printing_method: ""
    };
    description: any = "";

    timeFormat = timeFormat;
    // 列表
    getCardList(page?: number) {
        page && (this.pager.page = page);
        this.pager.request({
            callback: apiPhysicalVouchersCouponBackLists,
            params: {
                ...this.searchObj,
            },
        });
    }
    // 重置搜索
    reset(): void {
        Object.keys(this.searchObj).map((key) => {
            this.$set(this.searchObj, key, "");
        });
        this.getCardList(1);
    }
    viewAssociatedOrders(sn: any) {
        this.$emit("viewOrders", sn);
        // this.$router.push({
        //     path: "viewOrders",
        //     query: {
        //         sn: sn,
        //     },
        // });
        // EventBus.$emit("dataEvent", semiSeq);
    }
    // 去编辑商城
    toCardEdit(id: number | any): void {
        this.$router.push({
            path: "/card/makeCard/reserveCardDetail",
            query: {
                id: id,
            },
        });
    }
    //审批
    async toExamine(id: number | any, type: string) {

        this.approval(id, type)
    }

    approval(id:number | any, type:string) {
        apiPhysicalVouchersApprovalCouponBack({
            id: id,
            status: type,
            description: this.description
        }).then((res) => {
            this.$message.success('审核成功')
            this.getCardList();
        }).catch((res) => {
            this.$message.error(res.msg);
        });
    }

    created() {
        this.getCardList(1)
    }

    closeExamine() {
        this.description = "";
    }
}
