





















































































































































































































import EventBus from "@/utils/EventBus";
import {
    apiOrderLists,
    apiOrderApproval,
} from "@/api/card/physical_vouchers";
import { Component, Watch, Prop, Vue } from "vue-property-decorator";
import lsPagination from "@/components/ls-pagination.vue";
import LsDialog from "@/components/ls-dialog.vue";
import { RequestPaging, debounce } from "@/utils/util";
import ExportData from "@/components/export-data/index.vue";

@Component({
    components: {
        lsPagination,
        ExportData,
        LsDialog,
    },
})
export default class cardSaleOverviewList extends Vue {
    apiExport = apiOrderLists;
    @Prop() back_sn!: string;

    pager: RequestPaging = new RequestPaging();
    searchObj: any = {
        order_sn: "",
        admin_name: "",
        create_time: "",
        back_sn: "",
        product_profile_name: "",
        audit_status: "",
        product_type: "",
        company_name: "",
        scope_of_application: "",
        shop_name: ""
    };

    description: string = "";

    @Watch("back_sn", { immediate: true })
    handlesemiSeq(value: any) {
        if (value) {
            this.searchObj = {};
            this.searchObj.back_sn = value;
            this.getCardList();
        }
    }
    created() {
        this.getCardList(1)
    }
    // 列表
    getCardList(page?: number) {
        page && (this.pager.page = page);
        this.pager.request({
            callback: apiOrderLists,
            params: {
                ...this.searchObj,
            },
        });
    }
    // 重置搜索
    reset(): void {
        Object.keys(this.searchObj).map((key) => {
            this.$set(this.searchObj, key, "");
        });
        this.getCardList(1);
    }
    // 去编辑商城
    toCardEdit(id: number | any): void {
        this.$router.push({
            path: "/card/makeCard/voucherCardDetail",
            query: {
                id: id,
            },
        });
    }

        //审批
    async toExamine(id: number | any, type: string | number) {
        await this.approval(id, type)
    }

    approval(id:number | any, type:string | number) {
        apiOrderApproval({
            id: id,
            status: type,
            description: this.description
        }).then((res:any) => {
            this.$message.success('操作成功')
            this.getCardList();
        }).catch((res:any) => {
            this.$message.error(res.msg);
        });
    }

    closeExamine() {
        this.description = "";
    }
}
