


















import { Component, Vue, Prop } from "vue-property-decorator";
import reserveCardList from "./reserveCard.vue";
import voucherCardList from "./voucherCard.vue";
@Component({
    components: {
        reserveCardList,
        voucherCardList,
    },
})
export default class materialCardList extends Vue {
    type: any = this.$veifyPer("view_back") ? 1 : 2;
    back_sn: any = "";
    //
    handleClick() {
        this.$router.push({
            query: {},
        });
    }
    viewOrders(back_sn: any) {
        this.back_sn = back_sn;
        this.type = "2";
    }
    created() {
        this.type = this.$route.query.type ? this.$route.query.type : "1";

    }
}
